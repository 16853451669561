import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Most of my projects come about from personal interest, or from a need for something at work.`}</p>
    <p>{`Feel free to send me an email below if you have questions about my creations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      