import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="PSSC Tech" link="https://pssc.tech/" bg="linear-gradient(to right, #e72c2a 0%, #e72c2a 100%)" mdxType="ProjectCard">
Subdomain, fast & reliable URL shortener hosted with Cloudflare Pages.
    </ProjectCard>
    <ProjectCard title="Email Signature Generator" link="mailto:ejack195@eq.edu.au?subject=Question regarding email signature generator from ElliotJackson.id.au" bg="linear-gradient(to right, #0d2c6b 0%, #0d2c6b 100%)" mdxType="ProjectCard">
Automatic email signature generation from CSV file & Active Directory attributes.
    </ProjectCard>
    <ProjectCard title="Home Assistant" link="https://home-assistant.io/" bg="linear-gradient(to right, #51c1f1 0%, #51c1f1 100%)" mdxType="ProjectCard">
Open source home automation platform setup for multiple individuals & businesses.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      