import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`👋🏻 Hi, I'm Elliot!`}</h1>
    <p>{`I’m interested in technology and often play around with servers, networking hardware, workstations, home automation & other cool tech.`}</p>
    <p>{`I also have a side interest with Audio/Visual & Lighting.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      